import { builderStepsData, builderSystem } from '@/constants/sytemBuilderSteps'
import { useRouter } from 'next/router'
import { createContext, useState } from 'react'

const SystemBuilderContext = createContext()

export const SystemBuilderProvider = ({ children }) => {
  const [selectedProductList, setSelectedProductList] = useState([])
  const [builderSteps, setUpdateBuilderSteps] = useState(builderStepsData)
  const [system, setSystem] = useState(builderSystem)
  const [allTypeAttributeId, setAllTypeAttributeId] = useState([])
  const [stepLoading, setStepLoading] = useState(false)
  const [allStepResult, setAllStepResult] = useState([])

  const router = useRouter()

  const addProductToBag = (product_id, var_id, image_url, size, price, salePrice, modifiers) => {
    const tempData = selectedProductList.filter((product) => product.product_id !== product_id)
    setSelectedProductList([
      ...tempData,
      { product_id, var_id, image_url, size, price, salePrice, modifiers },
    ])
  }

  const removeProductFromBag = (product_id, var_id) => {
    const tempData = selectedProductList.filter(
      (product) => product.product_id !== product_id || product.var_id !== var_id
    )
    setSelectedProductList([...tempData])
  }

  const emptyBag = () => {
    setSelectedProductList([])
  }

  const updateBuilderNextSteps = (currentStep, systemData1, systemData2) => {
    const startIndex = router?.asPath?.split('/')?.indexOf('system-builder')
    let attributeDataArray
    if (startIndex !== -1) {
      attributeDataArray = router?.asPath?.split('/')?.slice(startIndex + 1)
    }
    let builderStepsDuplicateData = [...builderStepsData]
    var indexToRemove = builderStepsDuplicateData.findIndex((obj) => obj.id === currentStep)

    // If index is found, remove the object
    if (indexToRemove !== -1) {
      builderStepsDuplicateData.splice(indexToRemove)
    }

    const notInArray2 = builderStepsData.filter(
      (obj1) => !builderStepsDuplicateData.some((obj2) => obj2.step_name === obj1.step_name)
    )

    const matchedObject = allStepResult.filter((obj1) =>
      notInArray2.some(
        (obj2) =>
          obj1.attribute_type === obj2.step_name || obj1.attribute_type === obj2.second_step_name
      )
    )

    const shortNames = matchedObject?.length && matchedObject.map((obj) => obj.short_name)

    const filteredArray2 =
      shortNames?.length &&
      attributeDataArray?.length &&
      attributeDataArray?.filter((item) => !shortNames.includes(item))
    if (systemData2) {
      router.push(
        `${
          filteredArray2
            ? '/system-builder/' + filteredArray2?.join('/')
            : router?.asPath?.split('?')?.[0]
        }/${systemData1?.short_name}/${systemData2?.short_name}`
      )
    } else {
      router.push(
        `${
          filteredArray2
            ? '/system-builder/' + filteredArray2?.join('/')
            : router?.asPath.split('?')?.[0]
        }/${systemData1?.short_name}`
      )
    }
  }

  const updateBuilderBackSteps = (currentStep) => {
    let newState = [...builderSteps]

    //to inactive after step
    newState[currentStep].complete = false
    newState[currentStep].active = false
    newState[currentStep - 1].active = true
    newState[currentStep - 1].complete = false
    setUpdateBuilderSteps(newState)
  }

  const valueToShare = {
    selectedProductList,
    addProductToBag,
    removeProductFromBag,
    emptyBag,
    builderSteps,
    setUpdateBuilderSteps,
    updateBuilderNextSteps,
    updateBuilderBackSteps,
    system,
    setSystem,
    setAllTypeAttributeId,
    allTypeAttributeId,
    setStepLoading,
    stepLoading,
    setAllStepResult,
    allStepResult,
  }

  return (
    <SystemBuilderContext.Provider value={valueToShare}>{children}</SystemBuilderContext.Provider>
  )
}

export default SystemBuilderContext
