export const builderStepsData = [
  {
    id: 1,
    name: 'gender',
    active: true,
    complete: false,
    next: false,
    step_name: 'gender',
  },
  {
    id: 2,
    name: 'hunt',
    active: false,
    complete: false,
    next: false,
    step_name: 'species',
  },
  {
    id: 3,
    name: 'location',
    active: false,
    complete: false,
    next: false,
    step_name: 'location',
  },
  {
    id: 4,
    name: 'weather',
    active: false,
    complete: false,
    next: false,
    step_name: 'temperature',
    isBoth: true,
    second_step_name: 'condition',
  },
  {
    id: 5,
    name: 'style',
    active: false,
    complete: false,
    next: false,
    step_name: 'weapon',
    isBoth: true,
    second_step_name: 'hunt_type',
  },
  {
    id: 6,
    name: 'results',
    active: false,
    complete: false,
    next: false,
    step_name: 'hunt_type',
  },
]

export const builderSystem = {
  gender: '',
  species: '',
  location: '',
  temperature: '',
  condition: '',
  weapon: '',
  hunt_type: '',
}
